import Footer from "../Components/footer";
import NavBar from "../Components/Navbar";
import classes from "./company.module.css"
import karim from "../Resources/Karim.jfif"
import abozahra from "../Resources/Abozahra.jpeg"
import mourad from "../Resources/Mourad.JPG"
import sherine from "../Resources/Sherine.jpeg"





const Company =()=>{

    return (
        <div className={classes.pagebody}>
        <NavBar/>

        <div style={{color:"white"}}>

            <div id="info" className={classes.div1}>
                <h4 className={classes.header4}>A Little To Know About Us! </h4>
                <p className={classes.text}>Evolution Creative Studio was established in 2021 by a team of incredible people with various backgrounds. 
</p>
<img className={classes.image} src={sherine} style={{width:"20rem", height:"17rem"}}  alt="Sherine Rashed "></img>
<h5 className={classes.header5}>Ms. Sherine Rashed </h5>
<h6>Chief Executive Officer</h6>
<p className={classes.text}>Sherine Rashed is a prominent figure in the Egyptian creative industry. In 2014, Ms. Rashed launched “Quotes Factory” Reading Agency, her first project in the creative field. “Quotes Factory” flourished in no time; readers, writers and authors flooded in to join, and in no time the agency was followed by 100 thousand book lovers. This inspired Ms. Rashed to take the extra mile and in 2018 she leveled up her passion and launched iRead Middle East which is an Arab initiative that aims to penetrate the human mind, creating and reserving a space in it for reading that stands against the competition of social media, and to win the human intellect. In less than a year the initiative reached over a million readers and it revived the intellectual community. iRead Middle East is still expanding and impacting thousands of intellects. In 2019, Ms. Rashed launched Evolution Creative Studio. With a belief that visuals have the power to influence people and evoke the right emotions, Evolution Creative Studio is here not only to entertain but also to impact through powerful messages and ideas.</p>
</div>


<div className={classes.div1}>
    <img className={classes.image} src={mourad} style={{width:"20rem", height:"17rem"}}  alt="Ahmed Mourad "></img>
    <h5 className={classes.header5}>Mr.Ahmed Mourad </h5>
    <h6> Board Member
</h6>
<p className={classes.text}>Mr. Ahmed Mourad is a prominent Egyptian writer. He graduated ranking first of his class from the High Cinema Institute with a specialization in Cinematography and his short movies received several awards in festivals in England, Ukraine, and France. 
Mr. Mourad started pursuing his writing career in 2007 with his first novel “Vertigo” which got translated to English and Italian; turned into a TV series, and received the Mediterranean Cultural Award from Italy and the Best Book Award in the Cairo International Book Fair in 2013.  Mr. Mourad’s success did not stop there, Vertigo was followed by “Diamond Dust” and “The Blue Elephant”, both of which got recreated into movies. In 2014, Mr. Mourad was on the shortlist for the Arab Poker Award for “The Blue Elephant”. Mr. Mourad has an abundance of remarkable publications like “1919”, “Land of God”, and “Murder for Amateurs”. Most of his novels got translated into multiple languages like English, German, Swiss, and Italian. In 2018, Mr. Mourad received the State’s Award for Excellence from the High Institute of Culture for his works. 
Mr. Mourad is very established in the media industry as well. He is a very successful scriptwriter and he teaches scriptwriting in Egypt and UAE. Also, Mr. Mourad has been hosting a Radio Show called Al-Tawleefa since 2019. 
Mr. Mourad was one of the founders of iRead Middle East initiative and now he is the Chairman of Evolution Creative Studio.</p>
</div>
<div className={classes.div1}>
<img className={classes.image} src={abozahra} style={{width:"auto", height:"40rem"}}  alt="Abo Zahra "></img>
<h5 className={classes.header5}>Mr. Ahmed Abo Zahra </h5>
<h6>Chief Legal Officer
</h6>
<p className={classes.text}>Mr. Ahmed Abo Zahra was born and raised in Alexandria. He is very prominent in the legal field. He is a Legal Consultant and Researcher based in UAE. He is specialized in copyrights, and commercial contracts. He was a board member at the Lawyers Syndicate in Alexandria. Moreover, Mr. Abo Zahra was a lecturer at the Law Institute of Alexandria, the Legal Clinic of Alexandria University, as well as several other legal training centers. Mr. Abo Zahra has several published legal works and researches. Mr. Abo Zahra is a cornerstone to Evolution Creative Studio; he is responsible for all the legalities from contracts to deals, terms and conditions. 
</p>
</div>
<div className={classes.div1}>
<img className={classes.image} src={karim} style={{width:"20rem"}}  alt="Karim Adam "></img>
<h5 className={classes.header5}>Mr. Karim Adam  </h5>
<h6>Art Director</h6>
<p className={classes.text}>Mr. Karim Adam is Evolution Creative Studio’s creative man. He graduated from the Faculty of Fine Arts from Alexandria University in 2019. Immediately afterwards, Mr. Adam started a hugely successful career in the arts industry. He worked as a graphic designer in multiple companies and organizations like: Digital Media PH, Qabila, Qsoft Holdings, Big Productions, New Black, Three60 Degree and Al-Sharq. He expanded his experience massively as he built an impressive portfolio with his freelancing. Mr. Adam developed a passion for designing book covers and has become one of the best in the field in the past 7 years. He designed more than 700 book covers with more than 20 publishing houses and was the youngest to get nominated to be the poster and identity designer for the 36th Cairo’s International Film festival.
</p>
</div>

<div id="persp" className={classes.div1}>
<h4 className={classes.header4}>Our Perspective !</h4>
<p className={classes.text}>With dreams flying high, with passion thriving hard, we created a place where creativity connects people. Evolution creative studio is where you could inspire and get inspired by the brightest minds of the field. We aim to get the creative community together under one hub, a creative valley, untap their potential, and harness their skills and expertise to provide you the hassle-free experience you deserve. We are here to help you develop your business innovatively and successfully.  Image, content, and brand; we craft creativity using modern technology.
 
</p>
</div>

{/* <div id="expert"className={classes.div1}>
<h4 className={classes.header4}>Our Expertise </h4>
<p className={classes.text}>Our way of working is unique. “We don’t understand why people are frightened of new ideas .we are frightened of the old ones”. 
Evolution creative studios assists the creative minds in securing their intellectual copyrights. From the initial process of online documentation to registration and accreditation,  evolution creative studios have their hands on in assisting the creators. We help your ideas reach the creative platforms and we are pro in pitching. 
Our expertise includes crafting engaging business creatives through  graphic design,  motion graphic design,  3D graphic design , event branding services etc… We are not just a creative agency or a branding agency or an advertising agency. We are all of it under one roof. With us you can harness the power of creativity,  innovation and determination. Bringing the best to the best of you with our expert team. 
</p>
 
</div> */}

<div id="unique"className={classes.div1}>
<h4 className={classes.header4}>Our Uniqueness! </h4>
<p className={classes.text}>We are one of a kind and we are not bragging about it! We are here to provide you with all your creative needs under one roof. We will help you build your projects from scratch, and we will never rest until you get the results you desire. With us, you can harness the power of creativity, innovation, and determination. We don’t understand why people are frightened of new ideas. We are frightened of the old ones. Evolution Creative Studio assists all creative minds in securing their intellectual copyrights. From the initial process of online documentation to registration and accreditation, Evolution Creative Studio has hands-on experience in assisting creators. We help your ideas reach the creative platforms, and we are professionals when it comes to pitching. With us, your idea has no choice but to reach the audience! Our expertise includes crafting engaging business creative briefs through graphic design, motion graphic design, 3D graphic design, event branding services, etc. In addition, we are here to build a network for creative minds to connect them and with every potential opportunity that can add to their and our success. </p>
 
</div>
</div>
        <Footer/>
        </div>

    )
}

export default Company;