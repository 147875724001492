import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';
import logo from "../Resources/PROFILE PIC.png"

export default function Footer() {
  return (
    <MDBFooter bgColor='dark' className='text-center text-lg-start text-muted '>
      
      <section className=''>
        <div className='container text-center text-md-start mt-5'>
          <div className='row mt-3'>
            <div className='col-md-3 col-lg-4 col-xl-3 mx-auto mb-4'>
            <img src={logo} style={{width:"60%"}}></img>
            
            </div>

            <div className='col-md-2 col-lg-2 col-xl-2 mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Home</h6>
              <p>
                <a href='#!' className='text-reset'>
                  Statistics
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Latest Projects
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Testimonials
                </a>
              </p>
           
            </div>

            <div className='col-md-3 col-lg-2 col-xl-2 mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Group</h6>
              <p>
                <a href='#!' className='text-reset'>
                  Pricing
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Settings
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Orders
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Help
                </a>
              </p>
            </div>

            <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <i className='fas fa-home me-3'></i>Capital Tower 
                   
              </p>
              <p>
                <i className='fas fa-home me-3'></i>
                      Business Bay Dubai, UAE 
              </p>
              <p>
                <i className='fas fa-envelope me-3'></i>
                info@example.com
              </p>
              <p>
                <i className='fas fa-phone me-3'></i> +971 58 547 6956
              </p>
              {/* <p>
                <i className='fas fa-print me-3'></i> + 01 234 567 89
              </p> */}
            </div>
          </div>
        </div>
      </section>

      
    </MDBFooter>
  );
}