import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import diamond from "../Resources/diamond.png"
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import branding from "../Resources/1-Branding.png"
import movie from "../Resources/2-Movie-and-TV.png"
import eventbrand from "../Resources/3-Event-Branding.png"
import sound from "../Resources/4-Sound-Design.png"
import copywrite from "../Resources/5-Copywriting.png"
import story from "../Resources/5-Storyboarding.png"
import pitching from "../Resources/6-Pitching.png"
import chimp from "../Resources/chimp.svg"


// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./Scards.css";

// import required modules
import { EffectCoverflow,  Pagination } from "swiper";



export default function Scards() {

  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="serv">
          <h3>CONCEPT</h3>
          <img src={chimp} style={{width:100} }></img>
          <p>Creative evolution studio believes in being focused,  consistent and enthused all the time to each client. Because we understand branding is just not a word,  its big-league!  With us you receive what’s unprecedented and we make it impressive every time. </p>
          <Link
           style={{textDecoration: "none" ,
  color: "black",
  backgroundColor: "white",
  borderRadius: "0.5rem",
  padding: "0.5rem",
  fontFamily: '29LT Bukra Regular',
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "1.2rem",
  width: "90%",
  textAlign: "center"}} 
  
  to="../services#Concept">LEARN MORE</Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="serv1">
        <h3 >BRANDING</h3>
          <img src={branding} style={{width:100} }></img>
          <p>Creative evolution studio believes in being focused,  consistent and enthused all the time to each client. Because we understand branding is just not a word,  its big-league!  With us you receive what’s unprecedented and we make it impressive every time. </p>
          <Link     style={{textDecoration: "none" ,
  color: "black",
  backgroundColor: "white",
  borderRadius: "0.5rem",
  padding: "0.5rem",
  fontFamily: '29LT Bukra Regular',
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "1.2rem",
  width: "90%",
  textAlign: "center"}}  to="../services#Branding">LEARN MORE</Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="serv2">
        <h3>EVENT BRANDING</h3>
        <img src={eventbrand} style={{width:100} }></img>
        <p>Creative evolution studio believes in being focused,  consistent and enthused all the time to each client. Because we understand branding is just not a word,  its big-league!  With us you receive what’s unprecedented and we make it impressive every time. </p>
        <Link    style={{textDecoration: "none" ,
  color: "black",
  backgroundColor: "white",
  borderRadius: "0.5rem",
  padding: "0.5rem",
  fontFamily: '29LT Bukra Regular',
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "1.2rem",
  width: "90%",
  textAlign: "center"}}  to="../services#Branding">LEARN MORE</Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="serv3">
        <h3>SOUND DESIGN</h3>
          <img src={sound} style={{width:100} }></img>
          <p>Creative evolution studio believes in being focused,  consistent and enthused all the time to each client. Because we understand branding is just not a word,  its big-league!  With us you receive what’s unprecedented and we make it impressive every time. </p>
          <Link     style={{textDecoration: "none" ,
  color: "black",
  backgroundColor: "white",
  borderRadius: "0.5rem",
  padding: "0.5rem",
  fontFamily: '29LT Bukra Regular',
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "1.2rem",
  width: "90%",
  textAlign: "center"}}  to="../services#Branding">LEARN MORE</Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="serv4">
        <h3>Movie and TV</h3>
          <img src={movie} style={{width:100} }></img>
          <p>Creative evolution studio believes in being focused,  consistent and enthused all the time to each client. Because we understand branding is just not a word,  its big-league!  With us you receive what’s unprecedented and we make it impressive every time. </p>
          <Link     style={{textDecoration: "none" ,
  color: "black",
  backgroundColor: "white",
  borderRadius: "0.5rem",
  padding: "0.5rem",
  fontFamily: '29LT Bukra Regular',
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "1.2rem",
  width: "90%",
  textAlign: "center"}}  to="../services#Branding">LEARN MORE</Link>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="serv5">
        <h3>COPYWRITING</h3>
          <img src={copywrite} style={{width:100} }></img>
          <p>Creative evolution studio believes in being focused,  consistent and enthused all the time to each client. Because we understand branding is just not a word,  its big-league!  With us you receive what’s unprecedented and we make it impressive every time. </p>
          <Link     style={{textDecoration: "none" ,
  color: "black",
  backgroundColor: "white",
  borderRadius: "0.5rem",
  padding: "0.5rem",
  fontFamily: '29LT Bukra Regular',
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "1.2rem",
  width: "90%",
  textAlign: "center"}}  to="../services#Branding">LEARN MORE</Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="serv6">
        <h3>STORYBOARDING</h3>
          <img src={story} style={{width:100} }></img>
          <p>Creative evolution studio believes in being focused,  consistent and enthused all the time to each client. Because we understand branding is just not a word,  its big-league!  With us you receive what’s unprecedented and we make it impressive every time. </p>
          <Link     style={{textDecoration: "none" ,
  color: "black",
  backgroundColor: "white",
  borderRadius: "0.5rem",
  padding: "0.5rem",
  fontFamily: '29LT Bukra Regular',
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "1.2rem",
  width: "90%",
  textAlign: "center"}}  to="../services#Branding">LEARN MORE</Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="serv7">
        <h3>PITCHING</h3>
          <img src={pitching} style={{width:100} }></img>
          <p>Creative evolution studio believes in being focused,  consistent and enthused all the time to each client. Because we understand branding is just not a word,  its big-league!  With us you receive what’s unprecedented and we make it impressive every time. </p>
          <Link     style={{textDecoration: "none" ,
  color: "black",
  backgroundColor: "white",
  borderRadius: "0.5rem",
  padding: "0.5rem",
  fontFamily: '29LT Bukra Regular',
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "1.2rem",
  width: "90%",
  textAlign: "center"}}  to="../services#Branding">LEARN MORE</Link>
          </div>
        </SwiperSlide>
       

      </Swiper>
    </>
  );
}
