import NavBar from "../Components/Navbar"
import 'bootstrap/dist/css/bootstrap.min.css';
import classes from "./home.module.css"
import Footer from "../Components/footer"
import Scards from "../Components/Scards"
import { useNavigate } from "react-router-dom";
import halfchimp from "../Resources/halfchimp.png"



const Home =()=>{
    let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `../companyinfo`; 

    navigate(path);
    window.scrollTo(0, 0);
  }
return(
    <div className={classes.pagebody}>
    <NavBar/>
<div>
    <div className={classes.section1}>
       
     <h2>EVOLUTION CREATIVE<br></br>STUDIO AGENCY </h2>
    <h4>THE CREATIVE VALLEY</h4>
 <p>Evolution Creative Studio is an entity that focuses mainly on collaborating with the world’s brightest and most creative minds to ensure that our clients enjoy the optimal experience they have always dreamt of. 
<br></br><br></br>We collaborate with top professionals in the field to ensure that each project is accomplished with high quality, complete accuracy, and full efficiency.

</p>
 <img className={classes.halfchimp} src={halfchimp} ></img>
</div>

 <div>
    <div className={classes.star}></div>
    <div className={classes.star1}> </div>
    <div className={classes.star2}> </div>
 </div>
<div  className={classes.section2}>
    <div className={classes.section2img}></div>
    <div className={classes.section2txt}>
<h3>Meet our Board!</h3>

<p>Evolution Creative Studio was established in 2021 by a team of incredible people from various backgrounds and with rich expertise. 
  </p>

    <button onClick={routeChange}> LEARN MORE</button>
       </div>
  
       </div>

       <div>
    <div className={classes.star3}> </div>
    <div className={classes.star4}> </div>
    <div className={classes.star5}> </div>
       </div>
     
  
  


     <div className={classes.gradient}></div> 

    <div className={classes.cardcont}>
    <div className={classes.servtext}>
    <h2> OUR SERVICES </h2> 
    <h4>Intellectual copyrights for creators </h4>
    <p>
We work to give you the recognition you need for your creativity. Something to yearn for and something to earn for. Our proficiency in documentation,  registration and accreditation of creative ideas is what you need to be more acknowledged. With more accurate and quick assistance we help you in acquiring your intellectual copyrights. 
If you are a creator and looking to get your own recognition you are in the right place. Create your own identity with Evolution Creative Studio. 
</p>
    </div>
    <Scards/>
    </div>
   

    <div  className={classes.section2}>
    <div className={classes.section2img}></div>
    <div className={classes.section2txt}>
<h3>Our Uniqueness! </h3>

<p>We are one of a kind and we are not bragging about it! We are here to provide you with all your creative needs under one roof. We will help you build your projects from scratch and we will never rest until you get the results you desire. With us, you can harness the power of creativity,  innovation and determination. 
We don’t understand why people are frightened of new ideas. We are frightened of the old ones.
</p>

       </div>

       </div>
    </div>
<Footer/>
   

    </div>
    
)

}

export default Home;